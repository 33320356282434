export const ConstantFontColor = {
  commanColor: "#0D242E",
  white: "#FFFFFF",
  buttonColor: "#63BE60",
  greyText: "#425571",
  borderColor: "#DFDFDF",
  lightBackgroundColor: "#F5F5F5",
  lightGreyText: "#9898A0",
  blackColor: "#000000",
};
