import React from "react";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { useInView } from "react-intersection-observer";
interface tittleProps {
  colorTitle: string;
  title?: string;
  textAlign?: string;
  titleColor?: string;
}
const Tittle: React.FC<tittleProps> = ({
  colorTitle,
  title,
  textAlign,
  titleColor,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <div>
      <h3
        ref={ref}
        className={`sm:text-base text-sm font-medium underline decoration-[#63BE604D] decoration-4 underline-offset-1 transition-all duration-700 ${
          inView ? "animate-focus-in-contract" : "opacity-0"
        }`}
        style={{
          color: ConstantFontColor.buttonColor,
          fontFamily: constantFontFamily.rubik,
          textAlign: textAlign as React.CSSProperties["textAlign"],
        }}
      >
        {colorTitle ?? ""}
      </h3>
      <h1
        className=" 2xl:text-4xl xl:text-3xl sm:text-xl text-xl font-normal text-left pt-5"
        style={{
          fontFamily: constantFontFamily.rubik,
          color: titleColor ?? ConstantFontColor.commanColor,
        }}
      >
        {title ?? ""}{" "}
      </h1>
    </div>
  );
};

export default Tittle;
