import React, { useEffect, useState } from "react";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import CommonButton from "../../button/CommonButton";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
interface ContactUsFormProps {
  mainTitle?: string;
  marginBottom?: string;
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  mainTitle,
  marginBottom,
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleSubmit = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", message: "" };

    if (!name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!EMAIL_PATTERN.test(email)) {
      newErrors.email = "Please enter a valid Email";
      isValid = false;
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (!isValid) {
      toast.error("Please fix the errors", {
        theme: "colored",
      });
    } else {
      toast.success("Request submitted successfully!", { theme: "colored" });

      setName("");
      setEmail("");
      setMessage("");
      setErrors({ name: "", email: "", message: "" });

      // You can also call your submit logic here:
      // ContactUsData();
    }
  };

  const validateField = (field: string, value: string) => {
    setErrors((prev) => ({
      ...prev,
      [field]:
        value.trim() === ""
          ? `${field[0].toUpperCase() + field.slice(1)} is required`
          : "",
    }));
  };

  // const { innerWidth } = window;
  // const widthSet = () => {
  //   if (innerWidth >= 1440) {
  //     return 410;
  //   } else if (innerWidth >= 1024) {
  //     return 530;
  //   } else if (innerWidth >= 768) {
  //     return 389;
  //   } else if (innerWidth >= 425) {
  //     return 243;
  //   } else if (innerWidth >= 375) {
  //     return 243;
  //   } else if (innerWidth >= 320) {
  //     return 243;
  //   } else {
  //     return 180;
  //   }
  // };
  const getWidth = () => {
    const width = window.innerWidth;

    if (width >= 1440) return 410;
    if (width >= 1024) return 530;
    if (width >= 768) return 389;
    if (width >= 425) return 243;
    if (width >= 375) return 243;
    if (width >= 320) return 243;
    return 180;
  };

  const [responsiveWidth, setResponsiveWidth] = useState(getWidth());

  useEffect(() => {
    const handleResize = () => {
      setResponsiveWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div
      ref={ref}
      className={` flex flex-col lg:px-11 lg:py-12 sm:px-9 sm:py-8 px-5 py-5 rounded-lg  z-10 shadow-lg md:w-[460px] lg:w-2/3 xl:w-[491px] 2xl:w-[500px] bottom-3 
        ${inView ? "animate-swing-in-top-fwd" : "opacity-0"}`}
      style={{ backgroundColor: ConstantFontColor.white, margin: marginBottom }}
    >
      <h3
        className="sm:text-2xl text-xl font-bold text-left"
        style={{
          color: ConstantFontColor.commanColor,
          fontFamily: constantFontFamily.rubik,
        }}
      >
        {mainTitle ?? ""}
      </h3>
      <p
        className="sm:text-lg text-sm font-normal mb-4 text-left"
        style={{
          color: ConstantFontColor.commanColor,
          fontFamily: constantFontFamily.rubik,
        }}
      >
        We are here for you! How can we help?
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="flex flex-col items-start">
          <span
            className="sm:text-base text-sm font-normal text-left"
            style={{
              color: ConstantFontColor.commanColor,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            Name
          </span>
          <input
            type="text"
            placeholder="Name"
            autoComplete="name"
            className={` w-full p-2 border rounded  ${
              errors.name ? "" : "mb-5"
            } `}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              if (errors.name && e.target.value.trim() !== "") {
                setErrors((prev) => ({ ...prev, name: "" }));
              }
            }}
          />
          {errors.name && (
            <span className="error text-red-600 text-[13px] ">
              {errors.name}
            </span>
          )}
          <span
            className="sm:text-base text-sm font-normal text-left sm:pt-3"
            style={{
              color: ConstantFontColor.commanColor,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            Email
          </span>
          <input
            required
            type="email"
            placeholder="Email"
            autoComplete="email"
            className="w-full p-2 border rounded mb-5"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {errors.email && (
            <span className="error text-red-600 text-[13px]">
              {errors.email}
            </span>
          )}
          <span
            className="sm:text-base text-sm font-normal text-left sm:pt-3 "
            style={{
              color: ConstantFontColor.commanColor,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            Message
          </span>
          <textarea
            placeholder="Message"
            className={` w-full p-2 border rounded ${
              errors.message ? "" : "mb-7"
            } `}
            rows={4}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              validateField("message", e.target.value);
            }}
          ></textarea>
          {errors.message && (
            <span className="error text-red-600 text-[13px] mb-3">
              {errors.message}
            </span>
          )}

          <div className="flex justify-center items-center w-full">
            <CommonButton
              title="Submit"
              backgroundColor={ConstantFontColor.buttonColor}
              radius={8}
              fontFamily={constantFontFamily.rubik}
              fontWeight={600}
              width={responsiveWidth}
              fontSize={15}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
