import { useRef, useState } from "react";
import Tittle from "../../../componets/common/tittle/Tittle";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { videoData } from "../../../constant/images/Images";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";

const IntroduceData = [
  {
    title: "Misson",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  {
    title: "Vision",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
];

const Introduce = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(true);
  console.log("🚀 ~ Introduce ~ isPaused:", isPaused);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;

    if (video.paused) {
      video.play();
      setIsPaused(false);
    } else {
      video.pause();
      setIsPaused(true);
    }
  };

  return (
    <div className="flex xl:flex-row sm:flex-col flex-col w-full 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 py-14 justify-between">
      <div className="xl:w-2/5  sm:w-full w-full ">
        <Tittle
          colorTitle="ABOUT US"
          title="We introduce ourself as fast growing & innovative company."
          textAlign="left"
        />
        <div className="pt-5 flex flex-col items-start">
          <span
            className="text-left font-normal text-base "
            style={{
              fontFamily: constantFontFamily.rubik,
              color: ConstantFontColor.greyText,
            }}
          >
            Which offers & distribute electrical products & services to
            customer, H&M Company aims to create value by offering a
            differential product range supported by unrivalled.{" "}
          </span>

          {IntroduceData.map((item, index) => {
            return (
              <div key={index} className="pt-5 flex flex-col items-start">
                <span
                  className="text-left font-semibold text-xl"
                  style={{
                    fontFamily: constantFontFamily.rubik,
                    color: ConstantFontColor.greyText,
                  }}
                >
                  {item?.title}
                </span>
                <p
                  className="text-left font-normal text-base pt-2"
                  style={{
                    fontFamily: constantFontFamily.rubik,
                    color: ConstantFontColor.greyText,
                  }}
                >
                  {item?.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="relative group flex justify-center items-center w-full sm:w-full lg:w-[550px] xl:w-2/4 cursor-pointer p-2 lg:pt-0 sm:pt-5 pt-5"
        onClick={togglePlayPause}
      >
        <video ref={videoRef} loop muted className="w-full rounded-lg">
          <source src={videoData.aboutUs} type="video/mp4" />
        </video>

        {isPaused && (
          <div className="absolute inset-0 flex justify-center items-center  bg-opacity-30 hover:bg-opacity-50 transition-opacity duration-500 rounded-lg">
            <FaPlay className="text-white text-4xl sm:text-5xl" />
          </div>
        )}

        {!isPaused && (
          <div className="absolute inset-0 flex justify-center items-center rounded-lg  bg-opacity-0 group-hover:bg-opacity-30 transition duration-500">
            <FaPause className="text-white text-4xl sm:text-5xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Introduce;
