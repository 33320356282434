import React from "react";
import Tittle from "../../../componets/common/tittle/Tittle";
import EnergyServicesCart from "./energyServicesCart/EnergyServicesCart";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import ChooseUs from "./whyChooseUS/ChooseUs";

const MainEnergyServices = () => {
  return (
    <div>
      <div
        className="flex justify-center items-center flex-col  py-14 md:bg-[linear-gradient(to_bottom,white_59%,#0D242E_59%)] sm:bg-[linear-gradient(to_bottom,white_47%,#0D242E_37%)] bg-[linear-gradient(to_bottom,white_50%,#0D242E_37%)]"
        style={{ backgroundColor: ConstantFontColor.lightBackgroundColor }}
      >
        <Tittle
          colorTitle="SPECIALISE IN THE ENERGY SERVICE"
          title="Sustainable Energy Services"
          textAlign="center"
        />
        <EnergyServicesCart />
      </div>
      <ChooseUs />
    </div>
  );
};

export default MainEnergyServices;
