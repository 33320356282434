import React from "react";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { LiaIndustrySolid } from "react-icons/lia";
import { HiOutlineUserGroup } from "react-icons/hi";
import { PiSolarPanel } from "react-icons/pi";
import { FaRegThumbsUp } from "react-icons/fa";
import { useMediaQuery } from "@mui/material";
import { useInView } from "react-intersection-observer";

const experienceData = [
  {
    id: 1,
    icon: (
      <LiaIndustrySolid className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />
    ),
    title: "8 Years",
    description: "Industry Experience",
  },
  {
    id: 2,
    icon: (
      <HiOutlineUserGroup className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />
    ),
    title: "280 +",
    description: "Workforce Strength",
  },
  {
    id: 3,
    icon: <FaRegThumbsUp className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />,
    title: "5,200+",
    description: "Happy Customer",
  },
  {
    id: 4,
    icon: <PiSolarPanel className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />,
    title: "90+MW",
    description: "Solar project",
  },
];

interface ExperienceProps {
  boxShadow?: React.CSSProperties;
}

const Experience: React.FC<ExperienceProps> = ({ boxShadow }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const containerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr 1fr" : "repeat(4, 1fr)", // 2 columns in mobile, 4 in desktop
    textAlign: "center",
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div
      ref={ref}
      className={` relative z-10 flex justify-center pb-14 lg:px-0 sm:px-14 px-5 ${
        inView ? "animate-fadeInDown" : "opacity-0"
      } `}
    >
      <div
        className={`bg-white shadow-lg rounded-lg flex  2xl:px-9 2xl:py-12 xl:px-12 xl:py-9 lg:px-11 lg:py-8 md-py-6 md-px-5 py-6 px-7 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:gap-8 xl:gap-6 justify-around
       `}
        style={{ ...containerStyle, ...boxShadow }}
      >
        {experienceData.map((item) => {
          const showBorder =
            !isMobile && (item.id === 1 || item.id === 2 || item.id === 3);
          const bottomBorder = isMobile && (item.id === 1 || item.id === 2);
          const leftBorder = isMobile && (item.id === 2 || item.id === 4);
          return (
            <div
              key={item?.id}
              className={`flex flex-col items-center 2xl:px-12 xl:px-8 md:px-5 py-5 px-2 `}
              style={{
                color: ConstantFontColor.greyText,
                borderRight: showBorder
                  ? `1px solid ${ConstantFontColor.borderColor}`
                  : "none",
                borderBottom: bottomBorder
                  ? `1px solid ${ConstantFontColor.borderColor}`
                  : "none",
                borderLeft: leftBorder
                  ? `1px solid ${ConstantFontColor.borderColor}`
                  : "none",
              }}
            >
              <div>{item?.icon}</div>

              <span
                className="font-semibold xl:text-xl md:text-lg text-sm text-center 2xl:py-5 xl:py-3"
                style={{ fontFamily: constantFontFamily.rubik }}
              >
                {item?.title}{" "}
              </span>
              <span
                className="xl:text-base md:text-sm text-xs font-normal text-center"
                style={{ fontFamily: constantFontFamily.rubik }}
              >
                {item?.description}{" "}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Experience;
