import { useState } from "react";
import Tittle from "../../../componets/common/tittle/Tittle";
import { CgMathPlus } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";

const questions = [
  { que: "Where can I watch?" },
  { que: "Tempus magna risus interdum ultricies sed urna?" },
  { que: "Augue in nibh urna volutpat mattis?" },
  { que: "Eu egestas sed sed posuere ultrices ?" },
  { que: " Elementum facilisi aliquam, nisi, orci vulputate?" },
];
const Faq = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setSelectedQuestion((prev) => (prev === index ? null : index));
  };

  return (
    <div className="flex justify-center items-center flex-col pt-20 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5">
      <Tittle
        colorTitle="FREQUENTLY ASKED QUESTIONS"
        title="Everything You Need to Know About Our Services and Solutions"
      />
      {/* <div className="bg-[#F5F5F5] w-full my-6">
        {questions?.map((que, index) => {
          return (
            <div className="flex flex-col p-5 rounded-md items-center border-t-2">
              <div className="flex justify-between w-full">
                <div
                  className="text-lg font-medium text-left "
                  style={{ fontFamily: constantFontFamily.rubik }}
                >
                  {que.que}{" "}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    console.log("index", index);
                    handleToggle(index);
                  }}
                >
                  {selectedQuestion === index ? (
                    <CgClose />
                  ) : (
                    <CgMathPlus className="text-xl" />
                  )}
                </div>
              </div>
              <div className="flex items-start">
                {selectedQuestion === index && (
                  <span
                    className="text-left text-lg font-light"
                    style={{
                      fontFamily: constantFontFamily.rubik,
                      color: ConstantFontColor.lightGreyText,
                    }}
                  >
                    Nibh quisque suscipit fermentum netus nulla cras porttitor
                    euismod nulla. Orci, dictumst nec aliquet id ullamcorper
                    venenatis. Fermentum sulla craspor ttitore ismod nulla.
                    Elit adipiscing proin quis est consectetur. Felis ultricies
                    nisi, quis malesuada sem odio. Potentiмnibh natoque amet
                    amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam
                    tincidunt condimentum nullam.
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div> */}
      <div className="bg-[#F5F5F5] w-full my-6">
        {questions?.map((que, index) => {
          const isOpen = selectedQuestion === index;

          return (
            <div
              key={index}
              className="flex flex-col p-5 rounded-md items-start border-t cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <div className="flex justify-between w-full items-center ">
                <div
                  className="sm:text-lg text-sm font-medium text-left"
                  style={{ fontFamily: constantFontFamily.rubik }}
                >
                  {que.que}
                </div>
                <div>
                  {isOpen ? <CgClose /> : <CgMathPlus className="text-xl" />}
                </div>
              </div>
              <div
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  isOpen ? " mt-4 opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                <span
                  className="block text-left sm:text-lg text-xs font-light"
                  style={{
                    fontFamily: constantFontFamily.rubik,
                    color: ConstantFontColor.lightGreyText,
                  }}
                >
                  Nibh quisque suscipit fermentum netus nulla cras porttitor
                  euismod nulla. Orci, dictumst nec aliquet id ullamcorper
                  venenatis. Fermentum sulla craspor ttitore ismod nulla. Elit
                  adipiscing proin quis est consectetur. Felis ultricies nisi,
                  quis malesuada sem odio. Potenti nibh natoque amet amet,
                  tincidunt ultricies et. Et nam rhoncus sit nullam diam
                  tincidunt condimentum nullam.
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
