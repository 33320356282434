import React from "react";
import TopBar from "../../componets/topBar/TopBar";
import Header from "../../componets/header/Header";
import TittleBanner from "../../componets/mainTittleBanner/TittleBanner";
import ClientsSay from "../../componets/common/clientSay/ClientsSay";
import Footer from "../../componets/footer/Footer";

const Services = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"SERVICES"} />
      <TittleBanner mainTitle="Services" subTitle="Services" />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default Services;
