import React from "react";
import CommonButton from "../button/CommonButton";
import { imageData } from "../../constant/images/Images";
import { ConstantFontColor } from "../../constant/ConstantFontColor";
import {
  FaArrowRightLong,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { LiaFacebookF } from "react-icons/lia";
import Tittle from "../common/tittle/Tittle";
import { constantFontFamily } from "../../constant/constantFontStyle/constantFontFamily";
import { useNavigate } from "react-router-dom";
import { TbClockHour3 } from "react-icons/tb";
import { MdOutlineEmail, MdPhoneInTalk } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";

const socialMediaIcons = [
  { icons: <FaLinkedinIn /> },
  { icons: <LiaFacebookF /> },
  { icons: <FaTwitter /> },
  { icons: <FaYoutube /> },
  { icons: <FaInstagram /> },
];

const footerTab = [
  { title: "Home" },
  { title: "About" },
  { title: "Services" },
  { title: "Gallery" },
  { title: "Contact" },
];

const contactDetails = [
  {
    type: "email",
    icon: (
      <MdOutlineEmail
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "info@hmcompany.in",
  },
  {
    type: "phone",
    icon: (
      <MdPhoneInTalk
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "+91 97147 38409",
  },
  {
    icon: (
      <CiLocationOn
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "4793 McLaughlin Loop, East Royce",
  },
  {
    icon: (
      <TbClockHour3
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "  Mon - Sat 8:00 - 17:30, Sunday - CLOSED",
  },
];

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

interface FooterProps {
  greenBanner?: boolean;
}

const Footer = ({ greenBanner }: FooterProps) => {
  const navigate = useNavigate();

  const exploreNavigate = (index: number) => {
    if (index === 0) {
      navigate("/");
      scrollToTop();
    } else if (index === 1) {
      navigate("/aboutUs");
      scrollToTop();
    }
    // else if (index === 2) {
    //   navigate("/services");
    //   scrollToTop();
    // }
    else if (index === 4) {
      navigate("/contactUs");
      scrollToTop();
    }
  };
  return (
    <footer
      className="relative mt-20"
      style={{ backgroundColor: ConstantFontColor.commanColor }}
    >
      {/* Green Banner */}
      {greenBanner && (
        <div
          className="absolute z-50 -top-20 left-1/2 transform -translate-x-1/2 w-[90%] md:w-4/5 lg:w-[68%] p-6 md:p-11 rounded-lg flex flex-col md:flex-row justify-between items-center shadow-lg"
          style={{ backgroundColor: ConstantFontColor.buttonColor }}
        >
          <h3
            className="text-base md:text-2xl xl:text-4xl font-medium text-center md:text-left sm:pb-0 pb-4"
            style={{
              color: ConstantFontColor.white,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            Smartest Way to Generate
            <br /> Electricity
          </h3>

          <CommonButton
            arrowIcon
            title="Get in Touch"
            backgroundColor={ConstantFontColor.commanColor}
            radius={4}
            onClick={() => {
              navigate("/contactUs");
              scrollToTop();
            }}
          />
        </div>
      )}

      {/*  Footer Content */}
      <div
        className={` pb-10 px-6 md:px-12 ${greenBanner ? "pt-24" : "pt-4"} `}
      >
        <div className="max-w-7xl mx-auto mt-10 grid grid-cols-1 md:grid-cols-3 sm:gap-20 gap-10">
          {/*  Left Logo & Info */}
          <div>
            <div className="xl:mr-10 lg:mr-5 2xl:w-[188px] 2xl:h-16 xl:w-40 lg:w-2/4 md:w-2/3 sm:w-2/4 w-2/4 cursor-pointer">
              <img
                src={imageData.hmSolarLogo}
                alt=""
                className="w-full h-full"
              />
            </div>
            <p
              className="text-base font-normal my-6 text-left"
              style={{
                color: ConstantFontColor.lightGreyText,
                fontFamily: constantFontFamily.rubik,
              }}
            >
              We Offer A Wide Range Of Quality Solar Panel Installation
              Services.
            </p>
            {/* Social Icons */}
            <div className="flex xl:pt-0 md:pt-3">
              {socialMediaIcons.map((items) => {
                return (
                  <div className="flex items-center">
                    <div
                      className="mr-8 text-sm"
                      style={{ color: ConstantFontColor.white }}
                    >
                      {items?.icons}{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/*  Middle: Navigation */}
          <div className="flex flex-col justify-center items-start">
            <Tittle colorTitle="EXPLORE" />
            <div className="grid grid-cols-2 gap-x-4 mt-2">
              {footerTab.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="text-[#9898A0] hover:text-white flex items-center space-x-2 m-2 cursor-pointer transition"
                    onClick={() => {
                      exploreNavigate(index);
                    }}
                  >
                    <FaArrowRightLong className="text-sm" />
                    <span
                      className="text-sm font-normal"
                      style={{ fontFamily: constantFontFamily.rubik }}
                    >
                      {item.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {/*  Right: Contact Info */}
          <div className="flex flex-col justify-center items-start">
            <Tittle colorTitle="CONTACT US" />
            <div>
              {contactDetails.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center text-[#9898A0] hover:text-white mt-3 cursor-pointer transition"
                  >
                    <div className="text-base">{item?.icon} </div>
                    <span
                      className="pl-2 text-sm font-normal text-left line-clamp-2 w-full sm:w-44 md:w-11/12 xl:w-52 2xl:w-80"
                      style={{ fontFamily: constantFontFamily.rubik }}
                      onClick={() => {
                        if (item.type === "email") {
                          window.location.href = `mailto:${item.name}`;
                        } else if (item.type === "phone") {
                          window.location.href = `tel:${item.name}`;
                        }
                      }}
                    >
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Copyright Section */}
      <div className="text-center text-gray-500 text-xs sm:text-sm border-t border-gray-700 py-4">
        © Copyrights are Reserved by H&M Solar Company
      </div>
    </footer>
  );
};

export default Footer;
