import { TextField, useMediaQuery } from "@mui/material";
import Tittle from "../../../componets/common/tittle/Tittle";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { imageData } from "../../../constant/images/Images";
import MenuItem from "@mui/material/MenuItem";
import CommonButton from "../../../componets/button/CommonButton";

const currencies = [
  {
    value: "System installer",
    label: "System installer",
  },
  {
    value: "System installer 1",
    label: "System installer 1",
  },
  {
    value: "System installer 2",
    label: "System installer 2",
  },
  {
    value: "System installer 3",
    label: "System installer 3",
  },
];

const { innerWidth } = window;

const widthSet = () => {
  if (innerWidth >= 1440) {
    return 510;
  } else if (innerWidth >= 1024) {
    return 530;
  } else if (innerWidth >= 768) {
    return 530;
  } else if (innerWidth >= 425) {
    return 243;
  } else if (innerWidth >= 375) {
    return 243;
  } else if (innerWidth >= 320) {
    return 243;
  } else {
    return 180;
  }
};
const RequestQuoteFormData = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className="flex 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 pt-10 flex-col justify-center items-center w-full">
      <Tittle
        colorTitle="Save Money, Save The Environment!"
        title="Request A Detailed Solar Estimate for Your Home"
      />
      <div className="flex xl:justify-between sm:justify-center justify-center items-center w-full pt-10 xl:flex-row sm:flex-col flex-col">
        <div className="xl:w-[789px] md:w-[552px]">
          <img
            src={imageData.requestQuote}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <form>
          <div
            className="2xl:w-[664px] xl:w-[552px] rounded-xl shadow-lg flex flex-col items-start 2xl:px-14 md:px-6 sm:px-5 px-5 2xl:py-10 sm:py-9 py-9 2xl:ml-0 xl:ml-8 sm:m-5 m-6"
            style={{ background: ConstantFontColor.white }}
          >
            <span
              className="text-lg font-medium text-left"
              style={{
                color: ConstantFontColor.commanColor,
                fontFamily: constantFontFamily.rubik,
              }}
            >
              Request a Quote
            </span>
            <div className="flex flex-col w-full">
              {/* Name , Email */}
              <div className="flex sm:flex-row flex-col justify-between w-full pt-4">
                <div className="flex flex-col items-start">
                  <span
                    className="text-base font-normal text-left"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Name
                  </span>
                  <TextField
                    required
                    type="text"
                    variant="outlined"
                    placeholder="Name"
                    className="2xl:w-64 sm:w-60 w-full"
                  />
                </div>
                <div className="flex flex-col items-start sm:pt-0 pt-3">
                  <span
                    className="text-base font-normal text-left"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Email
                  </span>

                  <TextField
                    required
                    type="email"
                    variant="outlined"
                    placeholder="email"
                    className="2xl:w-64 sm:w-60 w-full"
                  />
                </div>
              </div>
              {/* Phone , Address */}
              <div className="flex sm:flex-row flex-col justify-between w-full sm:py-4 py-3">
                <div className="flex flex-col items-start">
                  <span
                    className="text-base font-normal text-left"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Phone
                  </span>

                  <TextField
                    required
                    type="phone"
                    variant="outlined"
                    placeholder="phone"
                    className="2xl:w-64 sm:w-60 w-full"
                  />
                </div>
                <div className="flex flex-col items-start sm:pt-0 pt-3">
                  <span
                    className="text-base font-normal text-left"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Address
                  </span>

                  <TextField
                    required
                    type="address"
                    variant="outlined"
                    placeholder="address"
                    className="2xl:w-64 sm:w-60 w-full"
                  />
                </div>
              </div>
              <span
                className="text-lg font-medium text-left"
                style={{
                  color: ConstantFontColor.commanColor,
                  fontFamily: constantFontFamily.rubik,
                }}
              >
                System Data
              </span>
              {/* System installer */}
              <div className="flex w-full sm:flex-row flex-col justify-between pt-4">
                <div className="flex flex-col items-start">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    System installer?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue="System installer"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        {/* System installer */}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="flex flex-col items-start sm:pt-0 pt-3">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    System installer?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue="EUR"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {/* {option.label} */}
                        System installer
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {/* Monthly and solar system */}
              <div className="flex w-full sm:flex-row flex-col justify-between pt-4">
                <div className="flex flex-col items-start">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Monthly electric usage?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue=" Monthly electric usage?"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        Monthly electric usage?
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="flex flex-col items-start sm:pt-0 pt-3">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Solar system type?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue="EUR"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        Solar system type?
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {/* solar panel and martial roof */}
              <div className="flex sm:flex-row flex-col w-full justify-between pt-4">
                <div className="flex flex-col items-start">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Solar panel place?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue="EUR"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        Solar panel place?
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="flex flex-col items-start sm:pt-0 pt-3">
                  <span
                    className="text-base font-normal text-left pb-2"
                    style={{
                      color: ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                    }}
                  >
                    Martial on your roof?
                  </span>
                  <TextField
                    select
                    placeholder="System installer?"
                    className="2xl:w-64 sm:w-60 w-full text-left"
                    defaultValue="EUR"
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        Martial on your roof?
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <div className="pt-10 w-full justify-center flex">
              <CommonButton
                arrowIcon
                title="Submit Request"
                backgroundColor={ConstantFontColor.buttonColor}
                fontFamily={constantFontFamily.rubik}
                fontSize={isMobile ? "12px" : "16px"}
                fontWeight={700}
                width={widthSet()}
                radius={5}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestQuoteFormData;
