import Tittle from "../../../componets/common/tittle/Tittle";
import ContactUsForm from "../../../componets/common/contactUsForm/ContactUsForm";
import { constantFontFamily } from "../../../constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "../../../constant/ConstantFontColor";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineEmail, MdPhoneInTalk } from "react-icons/md";
import { imageData } from "../../../constant/images/Images";

const contactDetails = [
  {
    icon: (
      <MdOutlineEmail
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "info@hmcompany.in",
  },
  {
    icon: (
      <MdPhoneInTalk
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "+91 97147 38409",
  },
  {
    icon: (
      <CiLocationOn
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "4793 McLaughlin Loop, East Royce",
  },
];
const GetInTouch = () => {
  return (
    <div className="2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 py-16 flex justify-between xl:flex-row sm:flex-col flex-col">
      <div className="sm:w-2/4 w-full flex flex-col items-start">
        <Tittle colorTitle="CONTACT US" title="Get In Touch" textAlign="left" />
        <span
          className="text-left py-5 text-sm  sm:text-base font-normal xl:w-2/4 sm:w-full w-full"
          style={{
            fontFamily: constantFontFamily.rubik,
            color: ConstantFontColor.lightGreyText,
          }}
        >
          Are you ready to take your first step towards a more sustainable and
          energy-efficient future?
        </span>
        {contactDetails.map((item, index) => (
          <div key={index} className="flex items-center sm:py-3 py-2">
            <span className="mr-3">{item.icon}</span>
            <span
              className="text-sm sm:text-base font-normal"
              style={{
                fontFamily: constantFontFamily.rubik,
                color: ConstantFontColor.lightGreyText,
              }}
            >
              {item.name}
            </span>
          </div>
        ))}
        <div className="flex sm:space-x-8 sm:space-y-0 space-y-4 pt-5 xl:pb-0 pb-10 sm:flex-row flex-col">
          <img
            src={imageData.contactUs1}
            alt=""
            className="2xl:w-auto 2xl:h-auto xl:w-60 xl:h-56 lg:w-60 md:w-56 w-full h-full"
          />
          <img
            src={imageData.contactUs2}
            alt=""
            className="2xl:w-auto 2xl:h-auto xl:w-60 xl:h-56 lg:w-60 md:w-56 w-full h-full"
          />
        </div>
      </div>
      <ContactUsForm />
    </div>
  );
};

export default GetInTouch;
