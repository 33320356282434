import React, { useEffect, useRef, useState } from "react";
import { imageData } from "../../../../constant/images/Images";
import { ConstantFontColor } from "../../../../constant/ConstantFontColor";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ServiceCard from "./serviceCard/ServiceCard";

const ServicesData = [
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.waterPump,
    title: "Solar water pump",
    desc: "A solar pump is a system that uses solar energy to raise liquid from the ground to a higher level...",
  },
  {
    img: imageData.heaterSystem,
    title: "Solar water heater system",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.heaterSystem2,
    title: "Solar water heater system",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
];

const EnergyServicesCart = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const totalSteps = ServicesData.length; // Get dynamic step count
  const [currentStep, setCurrentStep] = useState(0);
  const [clickedButton, setClickedButton] = useState("");

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scrollContainerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
      setScrollLeft(scrollContainerRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !scrollContainerRef.current) return;

    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust the multiplier for scroll speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const useHorizontalScroll = () => {
    const elRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const el = elRef.current;
      if (el) {
        const onWheel = (e: any) => {
          if (e.deltaY === 0) return;
          e.preventDefault();
          el.scrollTo({
            left: el.scrollLeft + e.deltaY,
            behavior: "smooth",
          });
        };
        el.addEventListener("wheel", onWheel);
        return () => el.removeEventListener("wheel", onWheel);
      }
    }, []);
    return elRef;
  };

  const scrollContainerRef = useHorizontalScroll();

  const scrollToNext = () => {
    if (currentStep < totalSteps - 1) {
      setClickedButton("next");
      const newStep = currentStep + 1;
      setCurrentStep(newStep);

      if (newStep === totalSteps - 1) {
        setClickedButton("");
      }

      setTimeout(() => setClickedButton(""), 200);
    }
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const itemWidth = container.querySelector("div")?.offsetWidth || 0;
      const gap = 20;
      const scrollAmount = itemWidth + gap;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  const scrollToPrev = () => {
    if (currentStep > 0) {
      setClickedButton("prev");
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
      if (newStep === 0) {
        setClickedButton("");
      }

      setTimeout(() => setClickedButton(""), 200);
    }

    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const itemWidth = container.querySelector("div")?.offsetWidth || 0;
      const gap = 20;
      const scrollAmount = itemWidth + gap;
      container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };
  return (
    <div className="flex sm:max-w-[91vw] max-w-[85vw] justify-between items-center gap-3 md:gap-5 xl:gap-0 mt-7 lg:flex-row sm:flex-col flex-col">
      <button
        onClick={scrollToPrev}
        className="p-2 rounded-full lg:flex hidden"
        style={{
          backgroundColor:
            clickedButton === "prev"
              ? ConstantFontColor.commanColor
              : currentStep > 0
              ? ConstantFontColor.buttonColor
              : "gray",
          color: "white",
        }}
        disabled={currentStep === 0}
      >
        <FaArrowLeftLong />
      </button>
      <div className="flex max-w-full md:max-w-full lg:max-w-[88%] xl:max-w-[93%] 2xl:max-w-[91%] overflow-x-auto scroll-smooth scrollbar-hide">
        <div
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          className={`flex w-full md:w-[89vw] lg:w-[68vw] xl:w-[73vw] 2xl:w-[75vw] overflow-x-scroll cursor-grab scroll-smooth scrollbar-hide
            ${isDragging ? "cursor-grabbing" : ""}`}
          style={{
            scrollBehavior: "smooth",
          }}
        >
          {ServicesData.map((items) => {
            return <ServiceCard items={items} />;
          })}
        </div>
      </div>
      <button
        onClick={scrollToNext}
        className="p-2 rounded-full lg:flex hidden"
        style={{
          backgroundColor:
            clickedButton === "next"
              ? ConstantFontColor.commanColor
              : currentStep < totalSteps - 1
              ? ConstantFontColor.buttonColor
              : "gray",
        }}
        disabled={currentStep === totalSteps - 1}
      >
        <FaArrowRightLong
          className="text-lg"
          style={{ color: ConstantFontColor.white }}
        />
      </button>

      <div className="flex lg:hidden lg:space-x-0 sm:space-x-9 space-x-4">
        {" "}
        <button
          onClick={scrollToPrev}
          className="p-2 rounded-full "
          style={{
            backgroundColor:
              clickedButton === "prev"
                ? ConstantFontColor.commanColor
                : currentStep > 0
                ? ConstantFontColor.buttonColor
                : "gray",
            color: "white",
          }}
          disabled={currentStep === 0}
        >
          <FaArrowLeftLong />
        </button>
        <button
          onClick={scrollToNext}
          className="p-2 rounded-full "
          style={{
            backgroundColor:
              clickedButton === "next"
                ? ConstantFontColor.commanColor
                : currentStep < totalSteps - 1
                ? ConstantFontColor.buttonColor
                : "gray",
          }}
          disabled={currentStep === totalSteps - 1}
        >
          <FaArrowRightLong
            className="text-lg"
            style={{ color: ConstantFontColor.white }}
          />
        </button>
      </div>
    </div>
  );
};

export default EnergyServicesCart;
