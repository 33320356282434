import React from "react";
import Header from "../../componets/header/Header";
import TopBar from "../../componets/topBar/TopBar";
import HomeBanner from "./homeBanner/HomeBanner";
import HomeAboutsUs from "./homeAboutsUs/HomeAboutsUs";
import MainEnergyServices from "./energyServices/MainEnergyServices";
import ClientsSay from "../../componets/common/clientSay/ClientsSay";
import RecentProject from "../../componets/common/recentProject/RecentProject";
import OurPartner from "../../componets/common/ourPartner/OurPartner";
import Footer from "../../componets/footer/Footer";
import { imageData } from "../../constant/images/Images";
import Experience from "./experience/Experience";
const ourProjectData = [
  {
    image: imageData.recentProject1,
    name: "solar Rooftop Systems",
    location: "4793 McLaughlin Loop, East Royce",
  },
  {
    image: imageData.recentProject2,
    name: "solar Rooftop Systems",
    location: "Hane Junction, West Emmyside, Michigan",
  },
  {
    image: imageData.recentProject3,
    name: "solar Rooftop Systems",
    location: "Zieme Ville, Eldridgeside, Washington",
  },
  {
    image: imageData.recentProject4,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
];
const Home = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"HOME"} />
      <HomeBanner />
      <div className="2xl:mt-[-100px] xl:-mt-16 lg:-mt-16 md:-mt-14 -mt-24">
        <Experience />
      </div>
      <HomeAboutsUs />
      <MainEnergyServices />
      <RecentProject mainTitle showButton data={ourProjectData} limit={4} />
      <OurPartner />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default Home;
