import React from "react";
import Tittle from "../../../../componets/common/tittle/Tittle";
import { ConstantFontColor } from "../../../../constant/ConstantFontColor";
import { GiCheckMark } from "react-icons/gi";
import { constantFontFamily } from "../../../../constant/constantFontStyle/constantFontFamily";
import ContactUsForm from "../../../../componets/common/contactUsForm/ContactUsForm";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineDeploymentUnit } from "react-icons/ai";

const chooseData = [
  {
    text: "Best energy solution",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  {
    text: "24/7 Technical Support",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
];

const solutionData = [
  {
    icon: <HiOutlineCurrencyDollar className="sm:text-3xl text-xl" />,
    name: "Cost-Effective Solutions",
  },
  {
    icon: <AiOutlineDeploymentUnit className="sm:text-3xl text-xl" />,
    name: "Advanced Technology",
  },
  {
    icon: <HiOutlineLightBulb className="sm:text-3xl text-xl" />,
    name: "Customizable Solutions",
  },
];

const ChooseUs: React.FC = () => {
  return (
    <div
      className="flex w-full h-full 2xl:pt-28 xl:pt-12  items-start flex-col"
      style={{ backgroundColor: ConstantFontColor.commanColor }}
    >
      <div className="flex w-full xl:flex-row sm:flex-col flex-col justify-between 2xl:px-44 xl:items-start sm:items-center items-center xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 ">
        <div className="xl:w-[39%] sm:h-full flex  flex-col xl:items-start sm:items-center items-center xl:pb-0 sm:pb-11">
          <Tittle
            colorTitle="WHY CHOOSE US"
            title="We are Building a Sustainable Future"
            textAlign="left"
            titleColor={ConstantFontColor.white}
          />
          <div className="my-5">
            {chooseData.map((items) => {
              return (
                <div className="flex py-3 xl:justify-start sm:justify-center justify-center">
                  <div
                    className="flex justify-center items-center rounded-full sm:h-14 sm:w-14 w-[24px] h-[24px]"
                    style={{
                      backgroundColor: ConstantFontColor.buttonColor,
                      color: ConstantFontColor.white,
                    }}
                  >
                    <GiCheckMark className="sm:text-3xl text-xl" />
                  </div>
                  <div className="flex flex-col sm:pl-6 pl-3 sm:w-2/3 xl:w-96">
                    <span
                      className="sm:text-xl text-sm font-semibold text-left"
                      style={{
                        fontFamily: constantFontFamily.rubik,
                        color: ConstantFontColor.white,
                      }}
                    >
                      {items.text}{" "}
                    </span>
                    <span
                      className="sm:text-base text-xs font-normal text-left"
                      style={{
                        fontFamily: constantFontFamily.rubik,
                        color: ConstantFontColor.lightGreyText,
                      }}
                    >
                      {items.desc}{" "}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ContactUsForm mainTitle="Get in touch" marginBottom="0 0 -130px 0" />
      </div>
      <div
        className="flex xl:justify-start sm:justify-center justify-center sm:flex-row flex-col w-full 2xl:py-12 xl:py-10 sm:pt-40 pt-36  sm:pb-4 pb-4   2xl:px-44 xl:px-28 lg:px-12 sm:px-10 px-5"
        style={{ backgroundColor: ConstantFontColor.lightBackgroundColor }}
      >
        {solutionData.map((solutionItems) => {
          return (
            <div className="flex lg:flex-row sm:flex-col flex-row items-center my-2 sm:mx-5 xl:mx-0 ">
              <div
                className="flex justify-center items-center 2xl:h-16 2xl:w-16 sm:h-14 sm:w-14 h-8 w-8 rounded-full"
                style={{
                  backgroundColor: ConstantFontColor.buttonColor,
                  color: ConstantFontColor.white,
                }}
              >
                <div>{solutionItems?.icon} </div>
              </div>
              <span
                className="2xl:pl-4 xl:pl-2 md:pl-2 sm:pl-0 pl-3 lg:pt-0 sm:pt-3 xl:w-[171px] 2xl:w-[220px]  2xl:text-xl sm:text-base font-normal lg:text-left sm:text-center text-center"
                style={{
                  fontFamily: constantFontFamily.rubik,
                  color: ConstantFontColor.greyText,
                }}
              >
                {solutionItems?.name}{" "}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseUs;
